<template>
  <div class="modal-add-task-participants">
    <!-- <div class="add-task-participants-header">Участники</div> -->

    <div>
      <el-form ref="form" :model="form">
        <div class="app-form__group mb-4">
          <span class="input--label d-block mb-2">10 бальная</span>
          <el-input-number
            v-model="num"
            controls-position="right"
            @change="handleChange"
            :min="1"
            :max="10"
            style="width: 100%"
          ></el-input-number>
        </div>
      </el-form>
    </div>

    <div class="modal-footer-buts">
      <div class="d-flex justify-center">
        <el-button type="primary" size="medium">Сохранить</el-button>
        <el-button type="danger" plain size="medium">Отменить</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      num: 1,
    };
  },
};
</script>

<style>
</style>
