<template>
  <div class="p-pegas">
    <div class="card-table">
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :span="16">
            <div class="caz-blocks-sarcho-title">
              <div class="content-title d-flex align-center mr-2">{{$t('message.tests')}}</div>
              <div class="block-sarche">
                <div class="header__search d-flex">
                  <el-input
                    placeholder="поиск"
                    prefix-icon="el-icon-search"
                    v-model="sarcheBlocks"
                    size="small"
                    round
                  ></el-input>
                  <!-- end input -->
                </div>
              </div>
            </div>
          </el-col>

          <el-col :span="8" class="flex-style text-right">
            <el-button size="small" class="btu-color" @click="appModal = true">
              {{$t('message.create')}}
            </el-button>
            <el-dropdown class="setting-cheek ml-2">
              <el-button
                class="padding_none"
                size="small"
                icon="el-icon-open"
              ></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(column, index) in columns"
                  :key="index"
                >
                  <el-checkbox
                    :checked="column.show"
                    @change="column.show = !column.show"
                    >{{ column.title }}</el-checkbox
                  >
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->

      <div class="card-table-header table-crm-smart">
        <table class="table-img table-my-code table-bordered">
          <thead>
            <tr>
              <th class="w50p" v-if="columns.id.show">
                {{ columns.id.title }}
              </th>

              <th v-if="columns.manes.show">
                {{ columns.manes.title }}
              </th>

              <th v-if="columns.department.show">
                {{ columns.department.title }}
              </th>

              <th v-if="columns.subdivisions.show">
                {{ columns.subdivisions.title }}
              </th>

              <th v-if="columns.userAdmin.show">
                {{ columns.userAdmin.title }}
              </th>

              <th v-if="columns.evaluation.show">
                {{ columns.evaluation.title }}
              </th>

              <th v-if="columns.data.show">
                {{ columns.data.title }}
              </th>

              <th v-if="columns.settings.show">
                {{ columns.settings.title }}
              </th>
            </tr>

            <tr class="filter_sorche">
              <th v-if="columns.id.show">
                <el-input
                  clearable
                  size="mini"
                  v-model="filterForm.id"
                  :placeholder="columns.id.title"
                  class="id_input"
                ></el-input>
              </th>

              <th v-if="columns.manes.show">
                <el-input
                  clearable
                  size="mini"
                  v-model="filterForm.manes"
                  :placeholder="columns.manes.title"
                ></el-input>
              </th>

              <th v-if="columns.department.show">
                <el-select
                  v-model="filterForm.department"
                  filterable
                  clearable
                  :placeholder="columns.department.title"
                  size="mini"
                >
                  <el-option label="Активний" value="shanghai"></el-option>
                  <el-option label="Не астивний" value="beijing"></el-option>
                </el-select>
              </th>

              <th v-if="columns.subdivisions.show">
                <el-select
                  v-model="filterForm.subdivisions"
                  filterable
                  clearable
                  :placeholder="columns.subdivisions.title"
                  size="mini"
                >
                  <el-option label="Активний" value="shanghai"></el-option>
                  <el-option label="Не астивний" value="beijing"></el-option>
                </el-select>
              </th>

              <th v-if="columns.userAdmin.show">
                <el-input
                  clearable
                  size="mini"
                  v-model="filterForm.userAdmin"
                  :placeholder="columns.userAdmin.title"
                ></el-input>
              </th>

              <th v-if="columns.evaluation.show">
                <el-input
                  clearable
                  size="mini"
                  v-model="filterForm.evaluation"
                  :placeholder="columns.evaluation.title"
                ></el-input>
              </th>

              <th v-if="columns.data.show">
                <el-date-picker
                  :placeholder="columns.data.title"
                  v-model="filterForm.data"
                  size="mini"
                >
                </el-date-picker>
              </th>

              <th class="settinW" v-if="columns.settings.show"></th>
            </tr>
          </thead>

          <transition-group name="flip-list" tag="tbody">
            <tr v-for="user in list" :key="user.id" class="cursor-pointer">
              <td v-if="columns.id.show">{{ user.id }}</td>

              <td v-if="columns.manes.show" class="img-name-users">
                <img
                  src="../../../public/img/user.png"
                  class="img-table-user"
                  alt=""
                />
                <span> {{ user.manes }}</span>
              </td>

              <td v-if="columns.department.show">
                {{ user.department }}
              </td>

              <td v-if="columns.subdivisions.show">
                {{ user.subdivisions }}
              </td>

              <td v-if="columns.userAdmin.show">
                {{ user.userAdmin }}
              </td>

              <td v-if="columns.evaluation.show">
                <span class="my-el-tag el-tag el-tag--success">
                  {{ user.evaluation }}
                </span>

                <!-- <span class="my-el-tag el-tag el-tag--warning">
                  {{ user.evaluation }}
                </span>

                <span class="my-el-tag el-tag el-tag--danger">
                  {{ user.evaluation }}
                </span> -->
              </td>

              <td v-if="columns.data.show">{{ user.data }}</td>

              <td v-if="columns.settings.show" class="settings-td">
                <el-dropdown :hide-on-click="false" class="table-bottons-right">
                  <span class="el-dropdown-link more_icons">
                    <i class="el-icon-arrow-down el-icon-more-outline"></i>
                  </span>
                  <el-dropdown-menu
                    slot="dropdown"
                    class="dropdown-menumy-style"
                  >
                    <el-dropdown-item>
                      <span @click="tags = true">
                        <i class="el-icon-medal"></i> Оценить
                      </span>
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <span>
                        <i class="el-icon-circle-close"></i> Отклонить
                      </span>
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <span @click="appModal2 = true">
                        <i class="el-icon-circle-check"></i> Принять
                      </span>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </td>
            </tr>
          </transition-group>
        </table>
        <div class="my___pagination">
          <Pagination />
        </div>
      </div>

      <div class="app-modal app-modal__full modal-widt-70">
        <el-dialog :visible.sync="appModal" :fullscreen="true" width="">
          <div
            @click="appModal = false"
            class="close-modal-stick d-flex align-center p-fixed text-white p-2 font-bold pointer transition"
          >
            <div class="close-modal-icon text-center rounded-circle transition">
              <i class="el-icon-close"></i>
            </div>
            <span class="close-modal-text text-uppercase ml-2"></span>
          </div>
          <CrmCreate />
        </el-dialog>
      </div>

      <div class="app-modal app-modal__full modal-widt-70">
        <el-dialog :visible.sync="appModal2" :fullscreen="true" width="">
          <div
            @click="appModal2 = false"
            class="close-modal-stick d-flex align-center p-fixed text-white p-2 font-bold pointer transition"
          >
            <div class="close-modal-icon text-center rounded-circle transition">
              <i class="el-icon-close"></i>
            </div>
            <span class="close-modal-text text-uppercase ml-2"></span>
          </div>
          <CrmCreate2 />
        </el-dialog>
      </div>

      <el-dialog
        title="Оценить"
        :visible.sync="tags"
        width="30%"
        :append-to-body="true"
        class="add-participants"
      >
        <Evaluation />
      </el-dialog>
      <!-- end metka -->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Pagination from "@/components/pagination";
import CrmCreate from "./components/crm-create";
import CrmCreate2 from "./components/crm-create2";
import Evaluation from "./components/evaluationModal";
export default {
  name: "testing",
  components: {
    Pagination,
    CrmCreate,
    CrmCreate2,
    Evaluation,
  },

  data() {
    return {
      appModal: false,
      appModal2: false,
      currentPage2: 5,
      checkList: [],
      tags: false,
      form: {},
      value2: "",
      value3: "",
      value4: "",
      value5: "",
      sarcheBlocks: "",
      filterForm: {
        id: "",
        manes: "",
        department: "",
        subdivisions: "",
        userAdmin: "",
        evaluation: "",
        data: "",
      },
      list: [
        {
          id: "1",
          manes: "Малакали тикувчи ",
          department: "Тикувчи",
          subdivisions: "Открыто",
          userAdmin: "Ботир",
          evaluation: "5 из 10",
          data: "12.12.2020",
        },
        {
          id: "2",
          manes: "Малакали тикувчи ",
          department: "Тикувчи",
          subdivisions: "Открыто",
          userAdmin: "Ботир",
          evaluation: "5 из 10",
          data: "12.12.2020",
        },
        {
          id: "3",
          manes: "Малакали тикувчи ",
          department: "Тикувчи",
          subdivisions: "Открыто",
          userAdmin: "Ботир",
          evaluation: "5 из 10",
          data: "12.12.2020",
        },
        {
          id: "4",
          manes: "Малакали тикувчи ",
          department: "Тикувчи",
          subdivisions: "Открыто",
          userAdmin: "Ботир",
          evaluation: "5 из 10",
          data: "12.12.2020",
        },
        {
          id: "5",
          manes: "Малакали тикувчи ",
          department: "Тикувчи",
          subdivisions: "Открыто",
          userAdmin: "Ботир",
          evaluation: "5 из 10",
          data: "12.12.2020",
        },
        {
          id: "6",
          manes: "Малакали тикувчи ",
          department: "Тикувчи",
          subdivisions: "Открыто",
          userAdmin: "Ботир",
          evaluation: "5 из 10",
          data: "12.12.2020",
        },
        {
          id: "7",
          manes: "Малакали тикувчи ",
          department: "Тикувчи",
          subdivisions: "Открыто",
          userAdmin: "Ботир",
          evaluation: "5 из 10",
          data: "12.12.2020",
        },
        {
          id: "8",
          manes: "Малакали тикувчи ",
          department: "Тикувчи",
          subdivisions: "Открыто",
          userAdmin: "Ботир",
          evaluation: "5 из 10",
          data: "12.12.2020",
        },
        {
          id: "9",
          manes: "Малакали тикувчи ",
          department: "Тикувчи",
          subdivisions: "Открыто",
          userAdmin: "Ботир",
          evaluation: "5 из 10",
          data: "12.12.2020",
        },
        {
          id: "10",
          manes: "Малакали тикувчи ",
          department: "Тикувчи",
          subdivisions: "Открыто",
          userAdmin: "Ботир",
          evaluation: "5 из 10",
          data: "12.12.2020",
        },
        {
          id: "11",
          manes: "Малакали тикувчи ",
          department: "Тикувчи",
          subdivisions: "Открыто",
          userAdmin: "Ботир",
          evaluation: "5 из 10",
          data: "12.12.2020",
        },
        {
          id: "12",
          manes: "Малакали тикувчи ",
          department: "Тикувчи",
          subdivisions: "Открыто",
          userAdmin: "Ботир",
          evaluation: "5 из 10",
          data: "12.12.2020",
        },
        {
          id: "13",
          manes: "Малакали тикувчи ",
          department: "Тикувчи",
          subdivisions: "Открыто",
          userAdmin: "Ботир",
          evaluation: "5 из 10",
          data: "12.12.2020",
        },
        {
          id: "14",
          manes: "Малакали тикувчи ",
          department: "Тикувчи",
          subdivisions: "Открыто",
          userAdmin: "Ботир",
          evaluation: "5 из 10",
          data: "12.12.2020",
        },
        {
          id: "15",
          manes: "Малакали тикувчи ",
          department: "Тикувчи",
          subdivisions: "Открыто",
          userAdmin: "Ботир",
          evaluation: "5 из 10",
          data: "12.12.2020",
        },
        {
          id: "16",
          manes: "Малакали тикувчи ",
          department: "Тикувчи",
          subdivisions: "Открыто",
          userAdmin: "Ботир",
          evaluation: "5 из 10",
          data: "12.12.2020",
        },
        {
          id: "17",
          manes: "Малакали тикувчи ",
          department: "Тикувчи",
          subdivisions: "Открыто",
          userAdmin: "Ботир",
          evaluation: "5 из 10",
          data: "12.12.2020",
        },
        {
          id: "18",
          manes: "Малакали тикувчи ",
          department: "Тикувчи",
          subdivisions: "Открыто",
          userAdmin: "Ботир",
          evaluation: "5 из 10",
          data: "12.12.2020",
        },
        {
          id: "19",
          manes: "Малакали тикувчи ",
          department: "Тикувчи",
          subdivisions: "Открыто",
          userAdmin: "Ботир",
          evaluation: "5 из 10",
          data: "12.12.2020",
        },
        {
          id: "20",
          manes: "Малакали тикувчи ",
          department: "Тикувчи",
          subdivisions: "Открыто",
          userAdmin: "Ботир",
          evaluation: "5 из 10",
          data: "12.12.2020",
        },
      ],
      columns: {
        id: {
          show: true,
          title: "№",
          sortable: true,
          column: "id",
        },
        manes: {
          show: true,
          title: "Ф.И.О",
          sortable: true,
          column: "manes",
        },
        department: {
          show: true,
          title: "Отдел",
          sortable: true,
          column: "department",
        },

        subdivisions: {
          show: true,
          title: "Подразделения",
          sortable: true,
          column: "subdivisions",
        },

        userAdmin: {
          show: true,
          title: "Пользователь",
          sortable: true,
          column: "userAdmin",
        },

        evaluation: {
          show: true,
          title: "Оценка",
          sortable: true,
          column: "evaluation",
        },

        data: {
          show: true,
          title: "Дата создания",
          sortable: true,
          column: "data",
        },
        settings: {
          show: true,
          title: "Профиль",
          sortable: false,
          column: "settings",
        },
      },
    };
  },
  // methods: {
  //   openDrawer() {
  //     this.appModal = !this.appModal;
  //   },
  // },
};
</script>

